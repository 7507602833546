import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '../../../public/SHDRCastle&Mickey.png';
const _hoisted_1 = {
  style: {
    "width": "100%",
    "height": "188px",
    "background": "linear-gradient(135deg, #4284FF 0%, #4284FF 49.33%, #B272FF 100%)",
    "position": "relative",
    "display": "inline-block"
  }
};
const _hoisted_2 = {
  style: {
    "position": "absolute",
    "top": "44px",
    "left": "20px"
  },
  class: "bannertitleot systemfont"
};
const _hoisted_3 = {
  style: {
    "position": "absolute",
    "top": "78px",
    "left": "20px"
  },
  class: "bannertitleof systemfont"
};
const _hoisted_4 = {
  style: {
    "padding": "20px 14px 12px 14px"
  }
};
const _hoisted_5 = {
  style: {
    "padding": "12px 14px"
  }
};
const _hoisted_6 = {
  key: 0
};
const _hoisted_7 = {
  key: 1
};
const _hoisted_8 = {
  key: 2
};
const _hoisted_9 = {
  key: 3
};
const _hoisted_10 = {
  style: {
    "padding": "12px 14px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('SubmitPage.EFapiaopro')), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('SubmitPage.EFapiaowait')), 1), _cache[0] || (_cache[0] = _createElementVNode("div", {
    style: {
      "position": "absolute",
      "top": "30px",
      "right": "22px"
    }
  }, [_createElementVNode("img", {
    src: _imports_0,
    style: {
      "max-width": "110px",
      "max-height": "137px"
    }
  })], -1))]), _createElementVNode("div", {
    class: "contentcsd",
    style: _normalizeStyle({
      height: '466px',
      width: $data.tempwidth
    })
  }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss)
  }, _toDisplayString(_ctx.$t('SubmitPage.Faamount')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.contcss)
  }, "¥ " + _toDisplayString($data.InvoiceAmount), 3)]), _createElementVNode("hr", {
    style: _normalizeStyle([{
      "border": "0.5px solid #EDEDF0",
      "margin": "0 14px"
    }, {
      width: $data.hrwidth
    }])
  }, null, 4), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss)
  }, _toDisplayString(_ctx.$t('SubmitPage.Fatype')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.contcss)
  }, [$data.InvoiceType == 'EC' ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('AppPage.Valueaddec')), 1)) : $data.InvoiceType == 'BEC' ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('AppPage.Valueaddbec')), 1)) : $data.InvoiceType == 'BES' ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('AppPage.Valueaddbes')), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_9, " "))], 2)]), _createElementVNode("hr", {
    style: _normalizeStyle([{
      "border": "0.5px solid #EDEDF0",
      "margin": "0 14px"
    }, {
      width: $data.hrwidth
    }])
  }, null, 4), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss)
  }, _toDisplayString(_ctx.$t('SubmitPage.Apptime')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.contcss)
  }, _toDisplayString($data.InvoiceDatetime), 3)])], 4)]);
}